"use client";

import { Box, Flex, Link } from "@radix-ui/themes";
import { HeadingV2 } from "@/design-system/components/text/HeadingV2";
import { TextV2 } from "@/design-system/components/text/TextV2";
import Image from "next/image";
import animatedImage from "../../../public/images/coaching_animated_v1.gif";
import { ButtonV2 } from "@/design-system/components/button/ButtonV2";
import styles from "./NotFound.module.scss";

type NotFoundProps = {
  title: string;
  subtitle: string;
  cta?: string;
  message?: React.ReactNode;
};
export default function NotFound({
  title,
  subtitle,
  cta,
  message,
}: NotFoundProps) {
  return (
    <Flex
      className={styles.container}
      direction={"column"}
      justify={"center"}
      p={{ initial: "4", sm: "9" }}
    >
      <Flex
        direction={{ initial: "column", sm: "row" }}
        gap={"8"}
        align={"center"}
      >
        <Flex
          width={{ initial: "100%", sm: "50%" }}
          justify={{ initial: "center", sm: "end" }}
        >
          <Image
            className={styles.image}
            src={animatedImage}
            alt={title}
            width={280}
            height={280}
          />
        </Flex>

        <Flex
          direction={"column"}
          width={{ initial: "100%", sm: "50%" }}
          gap={"4"}
          align={{ initial: "center", sm: "start" }}
        >
          <HeadingV2
            textStyle={"Display"}
            as={"h1"}
            align={{ initial: "center", sm: "left" }}
          >
            {title}
          </HeadingV2>
          <TextV2
            textStyle={"Headline L"}
            align={{ initial: "center", sm: "left" }}
          >
            {subtitle}
          </TextV2>
          {cta && (
            <Box mt={"5"}>
              <ButtonV2 size={"3"} color={"mint"} asChild>
                <Link href="/">{cta}</Link>
              </ButtonV2>
            </Box>
          )}
          {message && (
            <Box mt={"3"} maxWidth={"80%"}>
              {message}
            </Box>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}

"use client";
import { ComponentProps } from "react";
import { Link } from "@/i18n/routing";

// This is a wrapper around the Link component that forces it "uses client".
// This seems to make it composable with Button when used outside the client,
// , though not yet sure why that is necessary.
export default function ClientSideLink({
  href,
  ...rest
}: ComponentProps<typeof Link>) {
  return <Link href={href} {...rest} />;
}

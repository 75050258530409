import { defineRouting } from "next-intl/routing";
import { createNavigation } from "next-intl/navigation";

export const locales = ["en", "de"] as const;

export const routing = defineRouting({
  // A list of all locales that are supported
  locales: locales,

  // Used when no locale matches
  defaultLocale: "en",

  // Prefix locale to pathnames when needed (when not the default locale)
  localePrefix: "as-needed",

  // Pathnames for each locale
  pathnames: {
    "/": {
      en: "/",
      de: "/",
    },
    "/contact": {
      en: "/contact",
      de: "/kontakt",
    },
    "/find-a-therapist": {
      en: "/find-a-therapist",
      de: "/finde-einen-therapeuten",
    },
    "/find-a-therapist/online-therapy": {
      en: "/find-a-therapist/online-therapy",
      de: "/finde-einen-therapeuten/online-therapie",
    },
    "/find-a-therapist/speaking/[lang]": {
      en: "/find-a-therapist/speaking/[lang]",
      de: "/finde-einen-therapeuten/sprechende/[lang]",
    },
    "/find-a-therapist/[city_slug]": {
      en: "/find-a-therapist/[city_slug]",
      de: "/finde-einen-therapeuten/[city_slug]",
    },
    "/find-a-therapist/berlin/mitte": {
      en: "/find-a-therapist/berlin/mitte",
      de: "/finde-einen-therapeuten/berlin/mitte",
    },
    "/find-a-therapist/berlin/neukolln": {
      en: "/find-a-therapist/berlin/neukolln",
      de: "/finde-einen-therapeuten/berlin/neukolln",
    },
    "/find-a-therapist/berlin/kreuzberg": {
      en: "/find-a-therapist/berlin/kreuzberg",
      de: "/finde-einen-therapeuten/berlin/kreuzberg",
    },
    "/find-a-therapist/berlin/friedrichshain": {
      en: "/find-a-therapist/berlin/friedrichshain",
      de: "/finde-einen-therapeuten/berlin/friedrichshain",
    },
    "/find-a-therapist/berlin/pankow": {
      en: "/find-a-therapist/berlin/pankow",
      de: "/finde-einen-therapeuten/berlin/pankow",
    },
    "/find-a-therapist/berlin/charlottenburg-wilmersdorf": {
      en: "/find-a-therapist/berlin/charlottenburg-wilmersdorf",
      de: "/finde-einen-therapeuten/berlin/charlottenburg-wilmersdorf",
    },
    "/find-a-therapist/berlin/reinickendorf": {
      en: "/find-a-therapist/berlin/reinickendorf",
      de: "/finde-einen-therapeuten/berlin/reinickendorf",
    },
    "/find-a-therapist/berlin/lichtenberg": {
      en: "/find-a-therapist/berlin/lichtenberg",
      de: "/finde-einen-therapeuten/berlin/lichtenberg",
    },
    "/find-a-therapist/berlin/marzahn-hellersdorf": {
      en: "/find-a-therapist/berlin/marzahn-hellersdorf",
      de: "/finde-einen-therapeuten/berlin/marzahn-hellersdorf",
    },
    "/find-a-therapist/berlin/treptow-kopenick": {
      en: "/find-a-therapist/berlin/treptow-kopenick",
      de: "/finde-einen-therapeuten/berlin/treptow-kopenick",
    },
    "/find-a-therapist/berlin/tempelhof-schoneberg": {
      en: "/find-a-therapist/berlin/tempelhof-schoneberg",
      de: "/finde-einen-therapeuten/berlin/tempelhof-schoneberg",
    },
    "/find-a-therapist/berlin/spandau": {
      en: "/find-a-therapist/berlin/spandau",
      de: "/finde-einen-therapeuten/berlin/spandau",
    },
    "/find-a-therapist/berlin/steglitz-zehlendorf": {
      en: "/find-a-therapist/berlin/steglitz-zehlendorf",
      de: "/finde-einen-therapeuten/berlin/steglitz-zehlendorf",
    },
    "/find-a-therapist/[city_slug]/[profile_slug]": {
      en: "/find-a-therapist/[city_slug]/[profile_slug]",
      de: "/finde-einen-therapeuten/[city_slug]/[profile_slug]",
    },
    "/find-help-with/[category_slug]": {
      en: "/find-help-with/[category_slug]",
      de: "/finde-hilfe/[category_slug]",
    },
    "/for-companies": {
      en: "/for-companies",
      de: "/fuer-unternehmen",
    },
    "/for-companies/employee-assistance": {
      en: "/for-companies/employee-assistance",
      de: "/fuer-unternehmen/employee-assistance",
    },
    "/for-therapists": {
      en: "/for-therapists",
      de: "/fuer-therapeuten",
    },
    "/imprint": {
      en: "/imprint",
      de: "/impressum",
    },
    "/our-story": {
      en: "/our-story",
      de: "/unsere-geschichte",
    },
    "/privacy": {
      en: "/privacy",
      de: "/datenschutz",
    },
    "/security": {
      en: "/security",
      de: "/sicherheit",
    },
    "/sitemap": {
      en: "/sitemap",
      de: "/sitemap",
    },
    "/approaches": {
      en: "/approaches",
      de: "/ansatz",
    },
    "/specializations": {
      en: "/specializations",
      de: "/spezialgebiete",
    },
    "/cities": {
      en: "/cities",
      de: "/staedte",
    },
    "/languages": {
      en: "/languages",
      de: "/sprachen",
    },
    "/berlin-districts": {
      en: "/berlin-districts",
      de: "/berlin-bezirke",
    },
    "/support": {
      en: "/support",
      de: "/support",
    },
    "/support/[parent_category_slug]/[category_slug]/[faq_slug]": {
      en: "/support/[parent_category_slug]/[category_slug]/[faq_slug]",
      de: "/support/[parent_category_slug]/[category_slug]/[faq_slug]",
    },
    "/terms": {
      en: "/terms",
      de: "/agb",
    },
    "/profile/[uri]": {
      // I have no idea what this is for, I just copied it from our old Routes??
      en: "find-a-therapist{uri}",
      de: "finde-einen-therapeuten{uri}",
    },
    "/apply": {
      en: "/apply",
      de: "/apply",
    },
    "/matching/survey": {
      en: "/matching/survey",
      de: "/matching/survey",
    },
    "/find-help-with/online-therapy": {
      en: "/find-help-with/online-therapy",
      de: "/finde-hilfe/online-therapie",
    },
    "/for-employees": {
      en: "/for-employees",
      de: "/fuer-mitarbeiter",
    },
    "/self-tests/adhd": {
      en: "/self-tests/adhd",
      de: "/selbsttests/adhs",
    },
    "/self-tests/adhd/test": {
      en: "/self-tests/adhd/test",
      de: "/selbsttests/adhs/test",
    },
    "/self-tests/adhd/results": {
      en: "/self-tests/adhd/results",
      de: "/selbsttests/adhs/ergebnisse",
    },
    "/self-tests/anxiety": {
      en: "/self-tests/anxiety",
      de: "/selbsttests/angststoerung",
    },
    "/self-tests/anxiety/test": {
      en: "/self-tests/anxiety/test",
      de: "/selbsttests/angststoerung/test",
    },
    "/self-tests/anxiety/results": {
      en: "/self-tests/anxiety/results",
      de: "/selbsttests/angststoerung/ergebnisse",
    },
    "/self-tests/depression": {
      en: "/self-tests/depression",
      de: "/selbsttests/depression",
    },
    "/self-tests/depression/test": {
      en: "/self-tests/depression/test",
      de: "/selbsttests/depression/test",
    },
    "/self-tests/depression/results": {
      en: "/self-tests/depression/results",
      de: "/selbsttests/depression/ergebnisse",
    },
    "/self-tests/self-esteem": {
      en: "/self-tests/self-esteem",
      de: "/selbsttests/selbstwert",
    },
    "/self-tests/self-esteem/test": {
      en: "/self-tests/self-esteem/test",
      de: "/selbsttests/selbstwert/test",
    },
    "/self-tests/self-esteem/results": {
      en: "/self-tests/self-esteem/results",
      de: "/selbsttests/selbstwert/ergebnisse",
    },
    "/home/profile/create": {
      en: "/home/profile/create",
      de: "/home/profile/create",
    },
    "/home/profile/create/practice-info": {
      en: "/home/profile/create/practice-info",
      de: "/home/profile/create/practice-info",
    },
    "/home/profile/create/expertise-areas": {
      en: "/home/profile/create/expertise-areas",
      de: "/home/profile/create/expertise-areas",
    },
    "/home/profile/create/about-and-approach": {
      en: "/home/profile/create/about-and-approach",
      de: "/home/profile/create/about-and-approach",
    },
    "/home/profile/create/photos": {
      en: "/home/profile/create/photos",
      de: "/home/profile/create/photos",
    },
    "/home/profile/create/experience": {
      en: "/home/profile/create/experience",
      de: "/home/profile/create/experience",
    },
    "/home/profile/create/diplomas": {
      en: "/home/profile/create/diplomas",
      de: "/home/profile/create/diplomas",
    },
    "/emergency-resources": {
      en: "/emergency-resources",
      de: "/notfall-hilfe",
    },
  },
});

export type Pathnames = keyof typeof routing.pathnames;
export type Locale = (typeof routing.locales)[number];

// Lightweight wrappers around Next.js' navigation APIs
// that will consider the routing configuration
export const { Link, redirect, usePathname, useRouter, getPathname } =
  createNavigation(routing);

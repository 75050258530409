"use client";

import { useTranslations } from "next-intl";
import NotFound from "./NotFound";
import { TextV2 } from "@/design-system/components/text/TextV2";

function ClientError({ retry }: { retry: () => void }) {
  const t = useTranslations("ErrorBoudary");
  const handleRetry = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    retry();
  };
  const message = (
    <TextV2
      className={"containsDarkGreenLink"}
      textStyle={"Body XL"}
      align={{ initial: "center", sm: "left" }}
    >
      {t.rich("message", {
        retry: (chunks) => (
          <a href="#" onClick={handleRetry}>
            {chunks}
          </a>
        ),
        mail: (chunks) => (
          <a href="mailto:support@complicated.life">{chunks}</a>
        ),
      })}
    </TextV2>
  );
  return (
    <NotFound title={t("title")} subtitle={t("subtitle")} message={message} />
  );
}

export { ClientError };

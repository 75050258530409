import { TextV2 } from "@/design-system/components/text/TextV2";
import { Flex } from "@radix-ui/themes";
import { useTranslations } from "next-intl";
import Image from "next/image";
import React from "react";
import ExternalLink from "../Utility/ExternalLink";
import dataguardIcon from "../../../public/icons/dataguard.png";
import securityIcon from "../../../public/icons/security.svg";
import trustpilotIcon from "../../../public/icons/trustpilot.svg";

export default function SecurityIcons() {
  const t = useTranslations("Footer");

  return (
    <Flex direction="row" gap="3" align="center">
      <Flex direction="row" gap="2" align="center">
        <Image
          src={securityIcon}
          height={34}
          width={26}
          alt={t("security.gdpr")}
          style={{
            marginTop: "2px",
          }}
        />
        <Flex direction="column">
          <TextV2 textStyle={"Body M"} weightStyle={"medium"}>
            {t("security.gdpr")}
          </TextV2>
          <TextV2 textStyle={"Label XS"}>{t("security.gdpr_compliant")}</TextV2>
        </Flex>
      </Flex>
      <Flex align="center" justify="center">
        <ExternalLink url="https://www.dataguard.com/go/dataguard-seal/">
          <Image
            src={dataguardIcon}
            height={50}
            width={50}
            alt={t("security.dataguard")}
          />
        </ExternalLink>
      </Flex>
      <Flex direction="column">
        <Flex align="center">
          <ExternalLink url="https://www.trustpilot.com/review/complicated.life">
            <Image
              src={trustpilotIcon}
              height={23}
              width={94}
              alt={t("security.trustpilot_score")}
              style={{
                marginBottom: "3px",
              }}
            />
          </ExternalLink>
        </Flex>
        <TextV2 textStyle={"Label XS"}>{t("security.trustpilot_score")}</TextV2>
      </Flex>
    </Flex>
  );
}

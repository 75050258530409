"use client"
import React, { createContext, useContext } from 'react';

type DirectoryCardLayoutContextType = {
  showNewDirectoryCardLayout: boolean;
};

const DirectoryCardLayoutContext = createContext<DirectoryCardLayoutContextType>({
  showNewDirectoryCardLayout: false,
});

export function DirectoryCardLayoutProvider({ 
  children, 
  showNewDirectoryCardLayout 
}: { 
  children: React.ReactNode;
  showNewDirectoryCardLayout: boolean;
}) {
  return (
    <DirectoryCardLayoutContext.Provider value={{ showNewDirectoryCardLayout }}>
      {children}
    </DirectoryCardLayoutContext.Provider>
  );
}

export function useDirectoryCardLayout() {
  const context = useContext(DirectoryCardLayoutContext);
  if (context === undefined) {
    throw new Error('useDirectoryCardLayout must be used within a DirectoryCardLayoutProvider');
  }
  return context;
}
import React, { useRef } from "react";
import { FilterMenuLanguage, LanguageFilter } from "./FilterMenuLanguages";
import {
  FilterMenuLocationAndFormat,
  LocationAndFormatFilter,
} from "./FilterMenuLocationAndFormat";
import { Box, Flex, ScrollArea, Separator, Theme } from "@radix-ui/themes";
import styles from "./Filter.module.scss";
import { ButtonV2 } from "@/design-system/components/button/ButtonV2";
import {
  FilterMenuCost,
  StandalonePaymentsFilter,
} from "@components/Search/Filter/FilterMenuCost";
import {
  FilterMenuAdvanced,
  StandaloneAdvancedFilter,
} from "@components/Search/Filter/FilterMenuAdvanced";
import { NonMobileView } from "@/design-system/responsive-helpers/NonMobileView";
import * as Dialog from "@radix-ui/react-dialog";
import { DisableAncestorScrollOnMobile } from "@/design-system/responsive-helpers/DisableAncestorScrollOnMobile";
import { IconButton } from "@/design-system/components/IconButton";
import { MobileView } from "@/design-system/responsive-helpers/MobileView";
import { useTranslations } from "next-intl";
import { TextV2 } from "@/design-system/components/text/TextV2";
import {
  SearchFilterParams,
  SearchFiltersOptions,
  SearchParams,
} from "@/api/api.directory.search";
import FilterButton from "@components/Search/Filter/FilterButton";
import { FilterSearch } from "@components/Search/Filter/FilterSearch";
import { pushDataLayerEvent } from "@/analytics/analytics.datalayer";
import { FilterProps } from "@components/Search/Filter/FilterMenuProps";
import { Link } from "@/i18n/routing";
import {
  FilterMenuCategories,
  StandaloneCategoriesFilter,
} from "@components/Search/Filter/FilterMenuCategories";
import {
  FilterMenuFormat,
  FormatFilter,
} from "@components/Search/Filter/FilterMenuFormat";
import config from "@components/Search/config.search";
import { directory } from "@/analytics";
import { CaretLeftIcon, Cross2Icon } from "@radix-ui/react-icons";

const Filter = ({
  searchParams,
  setSearchParams,
  options,
  suppressLocationFilter,
}: FilterProps) => {
  const setFilters = React.useCallback(
    (filters: SearchParams["filters"]) => {
      setSearchParams({
        ...searchParams,
        page: 0,
        filters: {
          ...searchParams?.filters,
          ...filters,
        },
      });
    },
    [setSearchParams, searchParams],
  );

  return (
    <>
      <NonMobileView>
        <DesktopFilters
          searchParams={searchParams}
          options={options}
          setFilters={setFilters}
          suppressLocation={suppressLocationFilter || false}
        />
      </NonMobileView>
      <MobileView>
        <MobileFilters
          searchParams={searchParams}
          options={options}
          setFilters={setFilters}
          suppressLocation={suppressLocationFilter || false}
        />
      </MobileView>
    </>
  );
};

function DesktopFilters({
  searchParams,
  options,
  setFilters,
  suppressLocation,
}: {
  searchParams: SearchParams | null;
  options: SearchFiltersOptions;
  setFilters: (filters: SearchParams["filters"]) => void;
  suppressLocation: boolean;
}) {
  return (
    <Flex
      direction={"row"}
      justify={"between"}
      className={styles.filterBar}
      p={{ initial: "4", sm: "3" }}
      gap={"3"}
      align={"start"}
    >
      <Flex direction={"row"} align={"center"} justify={"start"} gap={"2"}>
        {suppressLocation ? (
          <FilterMenuFormat
            options={options}
            filters={searchParams?.filters}
            setFilters={setFilters}
          />
        ) : (
          <FilterMenuLocationAndFormat
            options={options}
            filters={searchParams?.filters}
            setFilters={setFilters}
          />
        )}

        <FilterMenuLanguage
          options={options}
          filters={searchParams?.filters}
          setFilters={setFilters}
        />
        <FilterMenuCategories
          options={options}
          filters={searchParams?.filters}
          setFilters={setFilters}
        />
        <FilterMenuCost
          options={options}
          filters={searchParams?.filters}
          setFilters={setFilters}
          hasPriceFilterChanged={hasPriceFilterChanged(searchParams?.filters)}
        />
        <FilterMenuAdvanced
          options={options}
          filters={searchParams?.filters}
          setFilters={setFilters}
        />
      </Flex>
      <FilterSearch
        options={options}
        filters={searchParams?.filters}
        setFilters={setFilters}
      />
    </Flex>
  );
}

enum FilterStep {
  FormatAndLocation,
  Language,
  Categories,
  Cost,
  Advanced,
}

function getLabelKey(step: FilterStep, suppressLocation: boolean) {
  switch (step) {
    case FilterStep.FormatAndLocation:
      return suppressLocation ? "format.label" : "location.label";
    case FilterStep.Language:
      return "language.label";
    case FilterStep.Categories:
      return "categories.content_label";
    case FilterStep.Cost:
      return "cost.label";
    case FilterStep.Advanced:
      return "advanced.label";
  }
}

function MobileFilters({
  searchParams,
  options,
  setFilters,
  suppressLocation,
}: {
  searchParams: SearchParams | null;
  options: SearchFiltersOptions;
  setFilters: (filters: SearchParams["filters"]) => void;
  suppressLocation: boolean;
}) {
  const t = useTranslations("Search.filters");
  const [open, setOpen] = React.useState(false);
  const [step, setStep] = React.useState<FilterStep>(
    FilterStep.FormatAndLocation,
  );
  const filterScrollDivRef: React.MutableRefObject<HTMLDivElement | null> =
    useRef(null);
  const onScrollFilterDivToTop = () => {
    filterScrollDivRef.current?.scrollTo({ top: 0, behavior: "smooth" });
  };

  const appliedSoFar = React.useMemo(() => {
    return searchParams ? filtersInUse(searchParams, suppressLocation) : 0;
  }, [searchParams, suppressLocation]);

  const labelKey = React.useMemo(() => {
    return getLabelKey(step, suppressLocation);
  }, [step, suppressLocation]);

  const resetStates = (isOpen: boolean) => {
    setOpen(isOpen);
    if (!isOpen) {
      setStep(FilterStep.FormatAndLocation);
    }
  };

  return (
    <Flex
      direction={"row"}
      justify={"between"}
      className={styles.filterBar}
      p={{ initial: "4", sm: "3" }}
      gap={"2"}
      align={"center"}
    >
      <GetMatched className={styles.mobileMainCTAs} />
      <Dialog.Root onOpenChange={resetStates} open={open}>
        <Dialog.Trigger asChild>
          <FilterButton
            className={styles.mobileMainCTAs}
            appliedCount={appliedSoFar}
            label={t("navigation.filters")}
            onClick={() => {
              setOpen(!open);
            }}
          ></FilterButton>
        </Dialog.Trigger>
        <Dialog.Portal>
          <Theme>
            <DisableAncestorScrollOnMobile>
              <Dialog.Content className={styles.mobileFilterContent}>
                <Flex
                  direction={"column"}
                  align={"center"}
                  px={"4"}
                  py={"6"}
                  height={"100%"}
                >
                  <MobileFilterHeader step={step} setStep={setStep} />
                  <Box mb={"5"} px={"5"} width={"100%"}>
                    <TextV2 textStyle={"Headline S"}>{t(labelKey)}</TextV2>
                  </Box>
                  <DialogSeparator />
                  <MobileFilterContent
                    step={step}
                    searchParams={searchParams}
                    options={options}
                    setFilters={setFilters}
                    suppressLocation={suppressLocation}
                    filterScrollDivRef={filterScrollDivRef}
                  />
                  <DialogSeparator />
                  <MobileFilterApplyButton
                    appliedSoFar={appliedSoFar}
                    onApply={() => {
                      setOpen(false);
                    }}
                  />
                  <MobileFilterFooter
                    step={step}
                    setStep={setStep}
                    onScrollFilterDivToTop={onScrollFilterDivToTop}
                    suppressLocation={suppressLocation}
                  />
                </Flex>
              </Dialog.Content>
            </DisableAncestorScrollOnMobile>
          </Theme>
        </Dialog.Portal>
      </Dialog.Root>
    </Flex>
  );
}

function MobileFilterHeader({
  step,
  setStep,
}: {
  step: FilterStep;
  setStep: (step: FilterStep) => void;
}) {
  const t = useTranslations("Search.filters");
  return (
    <Flex
      direction={"row"}
      justify={"between"}
      align={"center"}
      width={"100%"}
      mb={"5"}
      px={"5"}
    >
      <IconButton
        tertiary
        onClick={() => setStep(step - 1)}
        disabled={step == 0}
      >
        <CaretLeftIcon width={24} height={24} />
      </IconButton>
      <TextV2 textStyle={"Body M"}>
        {step < 4
          ? t("navigation.step", { current: step + 1, total: "4" })
          : t("advanced.label")}
      </TextV2>
      <Dialog.Close asChild>
        <IconButton tertiary aria-label={t("navigation.aria_close")}>
          <Cross2Icon width={24} height={24} />
        </IconButton>
      </Dialog.Close>
    </Flex>
  );
}

function MobileFilterContent({
  step,
  searchParams,
  options,
  setFilters,
  filterScrollDivRef,
  suppressLocation,
}: {
  step: FilterStep;
  searchParams: SearchParams | null;
  options: SearchFiltersOptions;
  setFilters: (filters: SearchParams["filters"]) => void;
  filterScrollDivRef: React.MutableRefObject<HTMLDivElement | null>;
  suppressLocation: boolean;
}) {
  return (
    <ScrollArea className={styles.filterScrollArea} ref={filterScrollDivRef}>
      <Box py="5" px={"5"}>
        {step === FilterStep.FormatAndLocation &&
          (suppressLocation ? (
            <FormatFilter
              options={options}
              filters={searchParams?.filters}
              setFilters={setFilters}
            />
          ) : (
            <LocationAndFormatFilter
              options={options}
              filters={searchParams?.filters}
              setFilters={setFilters}
            />
          ))}
        {step === FilterStep.Language && (
          <LanguageFilter
            options={options}
            filters={searchParams?.filters}
            setFilters={setFilters}
          />
        )}
        {step === FilterStep.Categories && (
          <StandaloneCategoriesFilter
            options={options}
            filters={searchParams?.filters}
            setFilters={setFilters}
          />
        )}
        {step === FilterStep.Cost && (
          <StandalonePaymentsFilter
            options={options}
            filters={searchParams?.filters}
            setFilters={setFilters}
          />
        )}
        {step === FilterStep.Advanced && (
          <StandaloneAdvancedFilter
            options={options}
            filters={searchParams?.filters}
            setFilters={setFilters}
          />
        )}
      </Box>
    </ScrollArea>
  );
}

function MobileFilterApplyButton({
  appliedSoFar,
  onApply,
}: {
  appliedSoFar: number;
  onApply: () => void;
}) {
  const t = useTranslations("Search.filters");
  return (
    <Dialog.Close asChild>
      <ButtonV2
        variant={"outline"}
        size={"4"}
        style={{ width: "100%" }}
        radius={"full"}
        onClick={onApply}
      >
        {appliedSoFar > 0
          ? t("navigation.apply", { count: appliedSoFar })
          : t("navigation.close")}
      </ButtonV2>
    </Dialog.Close>
  );
}

function MobileFilterFooter({
  step,
  setStep,
  onScrollFilterDivToTop,
  suppressLocation,
}: {
  step: FilterStep;
  setStep: (step: FilterStep) => void;
  onScrollFilterDivToTop: () => void;
  suppressLocation: boolean;
}) {
  const t = useTranslations("Search.filters");
  return (
    <Box p={"5"}>
      <ButtonV2
        variant={"ghost"}
        size={"4"}
        radius={"full"}
        onClick={() => {
          if (step === FilterStep.Advanced) {
            onScrollFilterDivToTop();
          } else {
            setStep(step + 1);
          }
        }}
      >
        {step === FilterStep.Advanced
          ? t("navigation.back_to_top")
          : t("navigation.next", {
              label: t(getLabelKey(step + 1, suppressLocation)),
            })}
      </ButtonV2>
    </Box>
  );
}

function DialogSeparator() {
  return (
    <Box px={"5"} width={"100%"}>
      <Separator size="4" />
    </Box>
  );
}

function GetMatched({ className }: { className?: string }) {
  const t = useTranslations("Search.filters.navigation");
  return (
    <ButtonV2 color={"mint"} size={"3"} className={className} asChild>
      <Link
        onClick={() => {
          directory.getMatchedClicked({ source: "filter_cta" });
          pushDataLayerEvent("get_matched_clicked");
        }}
        style={{ cursor: "pointer" }}
        href={"/matching/survey"}
      >
        {t("get_matched")}
      </Link>
    </ButtonV2>
  );
}

function filtersInUse(
  searchParams: SearchParams,
  suppressLocation: boolean,
): number {
  let count = 0;

  if (searchParams.filters) {
    const { filters } = searchParams;

    if (filters?.specialities && filters.specialities.length > 0) count++;
    if (filters?.approaches && filters.approaches.length > 0) count++;
    if (!suppressLocation && filters?.locations && filters.locations.length > 0)
      count++;
    if (filters.formats && filters.formats.length > 0) count++;
    if (filters.languages && filters.languages.length > 0) count++;
    if (filters.identities && filters.identities.length > 0) count++;
    if ((filters.insurances && filters.insurances.length > 0) || filters.isEap)
      count++;
    if (filters.genders && filters.genders.length > 0) count++;
    if (hasPriceFilterChanged(filters)) count++;
  }

  return count;
}

// Check if price filter values have changed or are the default ones
function hasPriceFilterChanged(filters?: SearchFilterParams) {
  return (
    filters?.price &&
    !(
      filters?.price.min === config.price_min &&
      filters?.price.max === config.price_max
    )
  );
}
export { Filter };

"use client";
import { LayoutContainerV2 } from "@/design-system/components/layout-container/LayoutContainerV2";
import React from "react";
import { FaqSection } from "@components/Faq/FaqSection";
import { HeadingV2 } from "@/design-system/components/text/HeadingV2";
import { Flex } from "@radix-ui/themes";
import { GoToFaqCta } from "@components/Faq/GoToFaqCta";
import { useTranslations } from "next-intl";
import { FrequentlyAskedCategoryWithFaqs } from "@/api/api.directory.faq";

type ClientFaqProps = {
  faq: FrequentlyAskedCategoryWithFaqs[];
};

function ClientFaq({ faq }: ClientFaqProps) {
  const t = useTranslations("FAQ");
  return (
    <LayoutContainerV2 size={"2"} py={"9"}>
      <Flex
        direction={"column"}
        align={"center"}
        gap={{ initial: "6", sm: "9" }}
      >
        <HeadingV2 textStyle={"Headline L"} align={"center"} as={"h2"}>
          {t("title")}
        </HeadingV2>
        <FaqSection
          faqCategories={faq}
          scrollToActiveQuestion={false}
          whiteLayout={true}
        />
        <GoToFaqCta isGreenLayout={true} />
      </Flex>
    </LayoutContainerV2>
  );
}

export { ClientFaq };

import { Skeleton } from "@/design-system/components/Skeleton";
import { useViewPortVisibility } from "@/hooks/useViewPortVisibility";
import { useTranslations } from "next-intl";
import React, { ForwardedRef, useState } from "react";
import { Box, Flex } from "@radix-ui/themes";
import { TextV2 } from "@/design-system/components/text/TextV2";
import { HeadingV2 } from "@/design-system/components/text/HeadingV2";
import styles from "./DirectoryCard.module.scss";
import FavoriteButton from "@components/Favorites/FavoriteButton";
import {
  SessionCity,
  SessionFormats,
  SessionPrice,
} from "@components/Profile/Tags/ProfileTags";
import { DirectoryPicture } from "@components/Profile/Picture/DirectoryPicture";
import classNames from "classnames";
import { ProfileCompact } from "@/api/api.directory.search";
import { LoyalClients } from "@/components/Profile/Tags/NewProfileTags";

type ShowPriceState = "all" | "non-eap-only" | "none";
type MaybeProfileProps = { profile: ProfileCompact | null };
type ShowPriceProps = { showPriceState: ShowPriceState };
type ShowCityProps = { showCity: boolean };
type DirectoryCardProps = {
  profile: ProfileCompact;
  uri: string;
  trackImpression: () => void;
  onClick?: () => void;
  showLoyalClients?: boolean;
} & ShowPriceProps;

function DirectoryCard({
  profile,
  uri,
  showPriceState,
  trackImpression,
  onClick,
  showLoyalClients = false,
}: DirectoryCardProps) {
  const cardRef = React.useRef<HTMLDivElement>(null);
  const isVisible = useViewPortVisibility(cardRef);
  const [isImpressionTracked, setImpressionTracked] = React.useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  React.useEffect(() => {
    if (profile && isVisible && !isImpressionTracked) {
      trackImpression();
      setImpressionTracked(true);
    }
  }, [isImpressionTracked, isVisible, profile, trackImpression]);

  const handleCardClicked = (e: React.MouseEvent) => {
    // Check if the click originated from the loyal clients badge
    // If yes, toggle the associated tooltip instead of opening the profile page
    const isLoyalClientsClicked = (e.target as HTMLElement).closest(
      "[data-loyal-clients-badge]",
    );
    if (isLoyalClientsClicked) {
      e.preventDefault();
      setTooltipOpen((prev) => !prev);
      return;
    }

    // Navigate to href in a new tab, ensure to prevent navigation if e.defaultPrevented is true,
    // which means we might have clicked on a link inside the card
    if (onClick) {
      onClick();
    }
    if (!e.defaultPrevented) {
      window.open(uri, "_blank");
    }
  };

  return (
    <a className={styles.cardAnchorWrapper} onClick={handleCardClicked}>
      <Box className={styles.cardContainer}>
        <DirectoryCardContentLayout
          ref={cardRef}
          profile={profile}
          showPriceState={showPriceState}
          showFavoriteButton={true}
          showLoyalClients={showLoyalClients}
          tooltipOpen={tooltipOpen}
          setTooltipOpen={setTooltipOpen}
        />
      </Box>
    </a>
  );
}

function DirectoryCardSkeleton() {
  return (
    <Box className={styles.cardContainer}>
      <DirectoryCardContentLayout
        profile={null}
        showPriceState={"none"}
        showFavoriteButton={true}
      />
    </Box>
  );
}

type DirectoryCardContentLayoutProps = {
  showPriceState: ShowPriceState;
  showFavoriteButton?: boolean;
  showLoyalClients?: boolean;
  tooltipOpen?: boolean;
  setTooltipOpen?: (open: boolean) => void;
} & MaybeProfileProps;

const DirectoryCardContentLayout = React.forwardRef(
  function ProfileContentLayout(
    {
      profile,
      showPriceState,
      showFavoriteButton,
      showLoyalClients,
      tooltipOpen,
      setTooltipOpen,
    }: DirectoryCardContentLayoutProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) {
    return (
      <Flex
        ref={ref}
        direction="column"
        gap={"4"}
        width={"100%"}
        className={styles.layoutSizer}
      >
        <Flex direction={"row"} gap={{ initial: "3", xs: "4", sm: "5" }}>
          <Box className={styles.imageWrapper}>
            <ProfilePicture profile={profile} />
          </Box>
          <Flex direction={"column"} className={styles.cardTextColumn}>
            <ResponseTimeAndFavorite
              profile={profile}
              className={styles.variant1And2}
              showFavoriteButton={showFavoriteButton}
            />
            <NameAndJobtitle
              fullName={profile?.full_name}
              jobtitle={profile?.jobtitle}
              showLoyalClientsBadge={
                showLoyalClients && profile?.has_loyal_clients
              }
              tooltipOpen={tooltipOpen}
              setTooltipOpen={setTooltipOpen}
            />
            <ProfileCategories
              profile={profile}
              className={styles.variant1And2}
            />
          </Flex>
        </Flex>
        <ResponseTimeAndFavorite
          profile={profile}
          className={styles.variant3}
          showFavoriteButton={showFavoriteButton}
        />
        <ProfileCategories profile={profile} className={styles.variant3} />
        <ProfileFooter
          profile={profile}
          showPriceState={showPriceState}
          showCity={true}
        ></ProfileFooter>
      </Flex>
    );
  },
);

const DirectoryCardContentMiniLayout = React.forwardRef(
  function ProfileContentLayout(
    { profile, showPriceState }: DirectoryCardContentLayoutProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) {
    return (
      <Flex
        ref={ref}
        direction="column"
        gap={"4"}
        width={"100%"}
        className={styles.layoutSizer}
      >
        <Flex direction={"row"} gap={{ initial: "3", xs: "4", sm: "5" }}>
          <Box className={styles.miniImageWrapper}>
            <ProfilePicture profile={profile} />
          </Box>
          <Flex direction={"column"} className={styles.cardTextColumn}>
            <NameAndJobtitle
              fullName={profile?.full_name}
              jobtitle={profile?.jobtitle}
            />
            <ProfileCategories
              profile={profile}
              mini
              className={styles.variant3}
            />
          </Flex>
        </Flex>
        <ProfileFooter
          profile={profile}
          showPriceState={showPriceState}
          showCity={false}
        ></ProfileFooter>
      </Flex>
    );
  },
);

function ProfilePicture({ profile }: MaybeProfileProps) {
  return (
    <DirectoryPicture
      pictureInfo={
        profile
          ? {
              profilePictureUrl: profile.profile_picture_url,
              fullName: profile.full_name,
            }
          : null
      }
      showPlayButton={profile?.has_active_video}
    />
  );
}

type MaybeProfileAndClassnameProps = MaybeProfileProps & { className?: string };
type ResponseTimeAndFavoriteProps = MaybeProfileAndClassnameProps & {
  showFavoriteButton?: boolean;
};

function ResponseTimeAndFavorite({
  profile,
  className,
  showFavoriteButton,
}: ResponseTimeAndFavoriteProps) {
  return (
    <Box className={className}>
      <Flex
        direction={"row"}
        align={"center"}
        width={"100%"}
        gap={"2"}
        justify={"between"}
      >
        <ResponseTime profile={profile} />
        {profile && showFavoriteButton && (
          <FavoriteButton
            therapist_user_id={profile.user_id}
            picture_url={profile.profile_picture_url}
            jobtitle={profile.jobtitle}
            full_name={profile?.full_name}
            therapist_first_name={profile.firstname}
            currentPage="landing_page"
          />
        )}
      </Flex>
    </Box>
  );
}

function ResponseTime({ profile }: MaybeProfileProps) {
  const t = useTranslations("Search.directory_card.response_times");
  if (!profile) {
    return <Skeleton kind="text" width={"150px"} />;
  }
  if (profile?.response_time) {
    let text = "";
    let isResponseTimeGood = true;
    if (profile.response_time < 3600 * 2) {
      text = t("within_two_hours");
    } else if (profile.response_time < 3600 * 4) {
      text = t("within_four_hours");
    } else if (profile.response_time < 3600 * 8) {
      text = t("within_eight_hours");
    } else if (profile.response_time < 3600 * 12) {
      text = t("within_twelve_hours");
    } else if (profile.response_time > 3600 * 24) {
      text = t("after_one_day");
      isResponseTimeGood = false;
    } else {
      text = t("within_one_day");
    }
    return (
      <TextV2
        textStyle={"Label XS"}
        className={classNames({
          [styles.responseTimeTextGood]: isResponseTimeGood,
          [styles.responseTimeTextBad]: !isResponseTimeGood,
        })}
      >
        {text.toUpperCase()}
      </TextV2>
    );
  } else {
    return (
      <>
        <div className={styles.variant1And2}></div>
        <TextV2
          textStyle={"Label XS"}
          className={styles.variant3}
          style={{ color: "var(--colorV2-grey-dark)" }}
        >
          {t("unknown").toUpperCase()}
        </TextV2>
      </>
    );
  }
}

function NameAndJobtitle({
  fullName,
  jobtitle,
  showLoyalClientsBadge = false,
  tooltipOpen,
  setTooltipOpen,
}: {
  fullName?: string;
  jobtitle?: string;
  showLoyalClientsBadge?: boolean;
  tooltipOpen?: boolean;
  setTooltipOpen?: (open: boolean) => void;
}) {
  return (
    <Flex direction={"column"}>
      <Flex
        direction={{ initial: "column-reverse", sm: "row" }}
        gap={"3"}
        align={{ initial: "start", sm: "center" }}
      >
        {fullName ? <FullName fullName={fullName} /> : <Skeleton kind="text" />}
        {showLoyalClientsBadge && (
          <Box className={styles.loyalClientsBadge}>
            <LoyalClients
              tooltipOpen={tooltipOpen}
              onTooltipOpenChange={setTooltipOpen}
            />
          </Box>
        )}
      </Flex>
      {jobtitle ? (
        <TextV2 textStyle={"Body M"} className={styles.jobTitle}>
          {jobtitle}
        </TextV2>
      ) : (
        <Skeleton kind="text" />
      )}
    </Flex>
  );
}

function FullName({ fullName }: { fullName: string }) {
  return (
    <>
      <HeadingV2
        textStyle={{
          initial: "Headline XL",
          sm: "Headline M",
          md: "Headline M",
          lg: "Headline M",
        }}
        as={"h3"}
        className={styles.variant1}
      >
        {fullName}
      </HeadingV2>
      <HeadingV2
        textStyle={{
          initial: "Headline L",
          sm: "Headline S",
          md: "Headline S",
          lg: "Headline S",
        }}
        as={"h3"}
        className={classNames(styles.name, styles.variant2And3)}
      >
        {fullName}
      </HeadingV2>
    </>
  );
}

function ProfileCategories({
  profile,
  className,
  mini,
}: {
  profile: ProfileCompact | null;
  className: string;
  mini?: boolean;
}) {
  return (
    <Box className={className}>
      <TextV2
        textStyle={"Body M"}
        className={mini ? styles.categoriesMini : styles.categories}
      >
        {profile ? (
          profile.top_categories.map((category) => category.name).join(" • ")
        ) : (
          <Skeleton kind="text" count={3} />
        )}
      </TextV2>
    </Box>
  );
}

function ProfileFooter({
  profile,
  showPriceState,
  showCity,
}: MaybeProfileProps & ShowPriceProps & ShowCityProps) {
  const showPriceForTherapist =
    profile &&
    profile.showPrice &&
    (showPriceState == "all" ||
      (showPriceState == "non-eap-only" && !profile.active_in_eap_program));
  return (
    <Flex direction={"row"} wrap={"wrap"} gap={"2"}>
      {showPriceForTherapist && (
        <SessionPrice
          priceAmount={profile.price_amount}
          priceCurrency={profile.price_currency}
        />
      )}
      {showCity && profile && <SessionCity name={profile.city.name} />}
      {profile && (
        <SessionFormats
          supportsInPersonTherapy={profile.supports_in_person_therapy}
          supportsVideoTherapy={profile.supports_video_therapy}
          supportsTextTherapy={profile.supports_text_therapy}
        />
      )}
      {/*{profile && <YearsOfExperience profile={profile} />}*/}
    </Flex>
  );
}

export {
  DirectoryCard,
  DirectoryCardSkeleton,
  DirectoryCardContentLayout,
  DirectoryCardContentMiniLayout,
};

export type { ShowPriceState };

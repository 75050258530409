import React from "react";
import styles from "./Pagination.module.scss";
import classNames from "classnames";

type PaginationProps = {
  total: number;
  size?: number;
  activePage: number;
  setActivePage: (n: number) => void;
  anchorPageTop?: string;
};

function Pagination({
  total,
  setActivePage,
  activePage,
  size,
  anchorPageTop,
}: PaginationProps) {
  if (total === 1) {
    // Don't show Pagination unless there are more than 1 pages
    return null;
  }

  const pages = [];

  const selectPage = (e: React.MouseEvent<HTMLElement>, page: number) => {
    setActivePage(page);
  };

  const pagesLinksTotal = size || 10;
  const start = Math.max(0, activePage + 1 - Math.floor(pagesLinksTotal / 2));
  const end = Math.min(start + pagesLinksTotal, total);
  for (let page = start; page < end; page++) {
    pages.push(
      <a
        className={classNames(styles.page, {
          [styles.isActive]: page === activePage,
        })}
        key={page}
        onClick={(e) => {
          selectPage(e, page);
        }}
        href={`#${anchorPageTop ?? ""}`}
      >
        {page + 1}
      </a>,
    );
  }
  return <ul className={styles.paginationContainer}>{pages}</ul>;
}

export { Pagination };

import { Skeleton } from "@/design-system/components/Skeleton";
import { useViewPortVisibility } from "@/hooks/useViewPortVisibility";
import { useTranslations } from "next-intl";
import React, { ForwardedRef } from "react";
import { Box, Flex, Separator } from "@radix-ui/themes";
import { TextV2 } from "@/design-system/components/text/TextV2";
import { HeadingV2 } from "@/design-system/components/text/HeadingV2";
import styles from "./NewDirectoryCard.module.scss";
import FavoriteButton from "@components/Favorites/FavoriteButton";
import {
  SessionFormatsIcons,
  // LoyalClients,
} from "@/components/Profile/Tags/NewProfileTags";
import { DirectoryPicture } from "@components/Profile/Picture/DirectoryPicture";
import { ProfileCompact } from "@/api/api.directory.search";
import getSymbolFromCurrency from "currency-symbol-map";
import classNames from "classnames";
import { SessionFormatAvailabilityText } from "@/components/Profile/ProfileBookingInfo/ProfileBookingInfo";

type ShowPriceState = "all" | "non-eap-only" | "none";
type MaybeProfileProps = { profile: ProfileCompact | null };
type ShowPriceProps = { showPriceState: ShowPriceState };
type ShowCityProps = { showCity: boolean };
type DirectoryCardProps = {
  profile: ProfileCompact;
  uri: string;
  trackImpression: () => void;
  onClick?: () => void;
} & ShowPriceProps;

function NewDirectoryCard({
  profile,
  uri,
  showPriceState,
  trackImpression,
  onClick,
}: DirectoryCardProps) {
  const cardRef = React.useRef<HTMLDivElement>(null);
  const isVisible = useViewPortVisibility(cardRef);
  const [isImpressionTracked, setImpressionTracked] = React.useState(false);

  React.useEffect(() => {
    if (profile && isVisible && !isImpressionTracked) {
      trackImpression();
      setImpressionTracked(true);
    }
  }, [isImpressionTracked, isVisible, profile, trackImpression]);

  const handleCardClicked = (e: React.MouseEvent) => {
    // Navigate to href in a new tab, ensure to prevent navigation if e.defaultPrevented is true,
    // which means we might have clicked on a link inside the card
    if (onClick) {
      onClick();
    }
    if (!e.defaultPrevented) {
      window.open(uri, "_blank");
    }
  };

  return (
    <a className={styles.cardAnchorWrapper} onClick={handleCardClicked}>
      <Box className={styles.cardContainer}>
        <DirectoryCardContentLayout
          ref={cardRef}
          profile={profile}
          showPriceState={showPriceState}
        />
      </Box>
    </a>
  );
}

function DirectoryCardSkeleton() {
  return (
    <Box className={styles.cardContainer}>
      <DirectoryCardContentLayout profile={null} showPriceState={"none"} />
    </Box>
  );
}

type DirectoryCardContentLayoutProps = {
  showPriceState: ShowPriceState;
} & MaybeProfileProps;

const DirectoryCardContentLayout = React.forwardRef(
  function ProfileContentLayout(
    { profile, showPriceState }: DirectoryCardContentLayoutProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) {
    return (
      <Flex
        ref={ref}
        direction="column"
        gap={"4"}
        width={"100%"}
        height={"100%"}
        className={styles.layoutSizer}
      >
        <Flex direction={{ initial: "column", sm: "row" }} height={"100%"}>
          <Box
            className={styles.imageWrapper}
            width={{ initial: "100%", sm: "34%" }}
          >
            <ProfilePicture profile={profile} />
            {profile && (
              <Box className={styles.favoriteButtonMobile}>
                <FavoriteButton
                  therapist_user_id={profile.user_id}
                  picture_url={profile.profile_picture_url}
                  jobtitle={profile.jobtitle}
                  full_name={profile?.full_name}
                  therapist_first_name={profile.firstname}
                  currentPage="landing_page"
                  isCTA={true}
                />
              </Box>
            )}
          </Box>
          <Flex
            direction={"column"}
            flexGrow={"1"}
            minWidth={"0"}
            width={{ initial: "100%", sm: "66%" }}
          >
            <NameAndJobtitle profile={profile} />
            <Box className={styles.seperator} px={"6"}>
              <Separator size={"4"} />
            </Box>
            <Flex
              direction={"column"}
              px={{ initial: "5", sm: "6" }}
              pt={{ initial: "0", sm: "4" }}
              pb={{ initial: "5", sm: "4" }}
              height={"100%"}
            >
              <Flex direction={"column"} gap={"4"}>
                <ResponseTime profile={profile} />
                <Flex direction={"column"}>
                  <SessionPrice
                    profile={profile}
                    showPriceState={showPriceState}
                  />
                  <Flex direction={"row"} gap={"2"} align={"center"}>
                    <SessionCity profile={profile} showCity={true} />
                    {profile && (
                      <Box className={styles.sessionFormatsIcons}>
                        <SessionFormatsIcons
                          supportsInPersonTherapy={
                            profile.supports_in_person_therapy
                          }
                          supportsVideoTherapy={profile.supports_video_therapy}
                          supportsTextTherapy={profile.supports_text_therapy}
                        />
                      </Box>
                    )}
                  </Flex>
                  {profile && (
                    <TextV2
                      className={styles.sessionFormatsText}
                      textStyle={{
                        initial: "Body XL",
                        sm: "Body M",
                        md: "Body M",
                        lg: "Body M",
                      }}
                    >
                      <SessionFormatAvailabilityText profile={profile} />
                    </TextV2>
                  )}
                </Flex>
                <Categories profile={profile} />
              </Flex>

              {/* Will be introduced later */}
              {/* {profile && (
                <Flex direction={"row"} gap={"2"} wrap={"wrap"} pt={"5"}>
                  <LoyalClients />
                </Flex>
              )} */}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    );
  },
);

const DirectoryCardContentMiniLayout = React.forwardRef(
  function ProfileContentLayout(
    { profile }: DirectoryCardContentLayoutProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) {
    return (
      <Flex
        ref={ref}
        direction="column"
        gap={"4"}
        width={"100%"}
        height={"inherit"}
        className={styles.layoutSizer}
      >
        <Flex direction={"row"} gap={{ initial: "3", xs: "4", sm: "5" }}>
          <Box className={styles.miniImageWrapper}>
            <ProfilePicture profile={profile} />
          </Box>
          <Flex direction={"column"} flexGrow={"1"} minWidth={"0"}>
            <NameAndJobtitle profile={profile} />
            <Categories profile={profile} />
          </Flex>
        </Flex>
      </Flex>
    );
  },
);

function ProfilePicture({
  profile,
}: MaybeProfileProps) {
  return (
    <DirectoryPicture
      pictureInfo={
        profile
          ? {
              profilePictureUrl: profile.profile_picture_url,
              fullName: profile.full_name,
            }
          : null
      }
      showPlayButton={profile?.has_active_video}
    />
  );
}

function ResponseTime({ profile }: MaybeProfileProps) {
  const t = useTranslations("Search.directory_card.response_times");
  if (!profile) {
    return <Skeleton kind="text" width={"150px"} />;
  }
  if (profile?.response_time) {
    let text = "";
    let isResponseTimeGood = true;
    if (profile.response_time < 3600 * 2) {
      text = t("within_two_hours");
    } else if (profile.response_time < 3600 * 4) {
      text = t("within_four_hours");
    } else if (profile.response_time < 3600 * 8) {
      text = t("within_eight_hours");
    } else if (profile.response_time < 3600 * 12) {
      text = t("within_twelve_hours");
    } else if (profile.response_time > 3600 * 24) {
      text = t("after_one_day");
      isResponseTimeGood = false;
    } else {
      text = t("within_one_day");
    }

    return (
      <TextV2
        textStyle={"Label XS"}
        className={classNames({
          [styles.responseTimeTextGood]: isResponseTimeGood,
          [styles.responseTimeTextBad]: !isResponseTimeGood,
        })}
        mt={"1"}
      >
        {text.toUpperCase()}
      </TextV2>
    );
  }
}

function NameAndJobtitle({ profile }: MaybeProfileProps) {
  return (
    <Flex
      direction={"column"}
      px={{ initial: "5", sm: "6" }}
      py={{ initial: "4", sm: "3" }}
    >
      <Flex direction={"row"} gap={"3"} align={"center"} justify={"between"}>
        {profile && profile.full_name ? (
          <HeadingV2
            textStyle={{
              initial: "Headline XL",
              sm: "Headline M",
              md: "Headline M",
              lg: "Headline M",
            }}
            as={"h3"}
            className={styles.name}
          >
            {profile.full_name}
          </HeadingV2>
        ) : (
          <Skeleton kind="text" />
        )}

        {profile && (
          <Box className={styles.favoriteButtonDesktop} mr={"-5px"}>
            <FavoriteButton
              therapist_user_id={profile.user_id}
              picture_url={profile.profile_picture_url}
              jobtitle={profile.jobtitle}
              full_name={profile?.full_name}
              therapist_first_name={profile.firstname}
              currentPage="landing_page"
            />
          </Box>
        )}
      </Flex>

      {profile && profile.jobtitle ? (
        <TextV2
          textStyle={{
            initial: "Body XL",
            sm: "Body M",
            md: "Body M",
            lg: "Body M",
          }}
          className={styles.jobTitle}
        >
          {profile.jobtitle}
        </TextV2>
      ) : (
        <Skeleton kind="text" />
      )}
    </Flex>
  );
}

function Categories({ profile }: { profile: ProfileCompact | null }) {
  return (
    <TextV2
      textStyle={{
        initial: "Body XL",
        sm: "Body M",
        md: "Body M",
        lg: "Body M",
      }}
      className={styles.categories}
    >
      {profile ? (
        profile.top_categories.map((category) => category.name).join(" • ")
      ) : (
        <Skeleton kind="text" count={3} />
      )}
    </TextV2>
  );
}

function SessionPrice({
  profile,
  showPriceState,
}: MaybeProfileProps & ShowPriceProps) {
  const t = useTranslations("Search.directory_card");
  const showPriceForTherapist =
    profile &&
    profile.showPrice &&
    (showPriceState == "all" ||
      (showPriceState == "non-eap-only" && !profile.active_in_eap_program));
  return (
    showPriceForTherapist && (
      <Flex direction={"row"}>
        <TextV2
          textStyle={{
            initial: "Body XL",
            sm: "Body M",
            md: "Body M",
            lg: "Body M",
          }}
        >
          {getSymbolFromCurrency(profile.price_currency)}
        </TextV2>
        <TextV2
          textStyle={{
            initial: "Body XL",
            sm: "Body M",
            md: "Body M",
            lg: "Body M",
          }}
        >
          {t("price_per_session", {
            price: profile.price_amount,
          })}
        </TextV2>
      </Flex>
    )
  );
}

function SessionCity({ profile, showCity }: MaybeProfileProps & ShowCityProps) {
  return (
    showCity &&
    profile && (
      <Flex className={styles.sessionCity}>
        {profile.city.name && (
          <TextV2
            textStyle={{
              initial: "Body XL",
              sm: "Body M",
              md: "Body M",
              lg: "Body M",
            }}
          >
            {profile.city.name}
          </TextV2>
        )}
        {profile.country && (
          <TextV2
            textStyle={{
              initial: "Body XL",
              sm: "Body M",
              md: "Body M",
              lg: "Body M",
            }}
          >
            , {profile.country}
          </TextV2>
        )}
        {!profile.city.name && !profile.country && (
          <Skeleton kind="text" width={100} />
        )}
      </Flex>
    )
  );
}

export {
  NewDirectoryCard,
  DirectoryCardSkeleton,
  DirectoryCardContentLayout,
  DirectoryCardContentMiniLayout,
};

export type { ShowPriceState };

import { useTranslations } from "next-intl";
import { TextV2 } from "@/design-system/components/text/TextV2";
import { Flex, Tooltip } from "@radix-ui/themes";
import { ChatBubbleIcon, LaptopIcon, PersonIcon } from "@radix-ui/react-icons";
import { useMoment } from "@/hooks/useMoment";
import { CalendarHeart, HeartHandshake } from "lucide-react";
import classNames from "classnames";
import styles from "../../Search/List/NewDirectoryCard.module.scss";
import { forwardRef } from "react";

function YearsOfExperience({ monthYearStarted }: { monthYearStarted: string }) {
  const t = useTranslations("Search.directory_card");
  const moment = useMoment();
  const givenDate = moment(monthYearStarted);
  const currentDate = moment();
  const yearsDifference =
    monthYearStarted === null ? 0 : currentDate.diff(givenDate, "years");

  if (yearsDifference < 1) {
    return null;
  }

  return (
    <ProfileTag>
      <CalendarHeart width={16} height={16} strokeWidth={2.1} />
      <TextV2
        textStyle={"Body S"}
        className={"truncate"}
        weightStyle={"medium"}
      >
        {t("years_of_experience", {
          years: yearsDifference,
        })}
      </TextV2>
    </ProfileTag>
  );
}

const LoyalClients = forwardRef<
  HTMLDivElement,
  {
    tooltipOpen?: boolean;
    onTooltipOpenChange?: (open: boolean) => void;
  }
>(({ tooltipOpen, onTooltipOpenChange }, ref) => {
  const t = useTranslations("Search.directory_card");
  const tooltipContent = t("loyal_clients_tooltip");

  return (
    <Tooltip
      ref={ref}
      content={tooltipContent}
      side="bottom"
      open={tooltipOpen}
      onOpenChange={onTooltipOpenChange}
    >
      <Flex data-loyal-clients-badge>
        <ProfileTag beigeLayout>
          <HeartHandshake width={16} height={16} strokeWidth={2.1} />
          <TextV2
            textStyle={"Body S"}
            className={"truncate"}
            weightStyle={"medium"}
          >
            {t("loyal_clients")}
          </TextV2>
        </ProfileTag>
      </Flex>
    </Tooltip>
  );
});
LoyalClients.displayName = "LoyalClients";

const SessionFormatsIcons = forwardRef<
  HTMLDivElement,
  {
    supportsInPersonTherapy: boolean;
    supportsVideoTherapy: boolean;
    supportsTextTherapy: boolean;
  }
>(
  (
    { supportsInPersonTherapy, supportsVideoTherapy, supportsTextTherapy },
    ref,
  ) => {
    const t = useTranslations("Search.directory_card.session_formats");

    const formatsTexts = [
      supportsInPersonTherapy && t("in_person"),
      supportsVideoTherapy && t("online"),
      supportsTextTherapy && t("text"),
    ].filter(Boolean);

    if (formatsTexts.length === 0) {
      return <></>;
    } else {
      let formatsText = "";
      if (formatsTexts.length === 1) {
        formatsText = t("formatters.one", { format: formatsTexts[0] });
      } else if (formatsTexts.length === 2) {
        formatsText = t("formatters.two", {
          format1: formatsTexts[0],
          format2: formatsTexts[1],
        });
      } else {
        formatsText = t("formatters.three", {
          format1: formatsTexts[0],
          format2: formatsTexts[1],
          format3: formatsTexts[2],
        });
      }
      return (
        <Tooltip ref={ref} content={formatsText} side="bottom">
          <Flex direction={"row"} wrap={"nowrap"} gap={"2"} align={"center"}>
            {supportsInPersonTherapy && (
              <ProfileTag iconLayout>
                <PersonIcon />
              </ProfileTag>
            )}
            {supportsVideoTherapy && (
              <ProfileTag iconLayout>
                <LaptopIcon />
              </ProfileTag>
            )}
            {supportsTextTherapy && (
              <ProfileTag iconLayout>
                <ChatBubbleIcon />
              </ProfileTag>
            )}
          </Flex>
        </Tooltip>
      );
    }
  },
);
SessionFormatsIcons.displayName = "SessionFormatsIcons";

const ProfileTag = forwardRef<
  HTMLDivElement,
  {
    children: React.ReactNode;
    beigeLayout?: boolean;
    iconLayout?: boolean;
    className?: string;
  }
>(({ children, beigeLayout, iconLayout }, ref) => {
  return (
    <Flex
      ref={ref}
      dir={"column"}
      align={"center"}
      gap={"2"}
      wrap={"nowrap"}
      flexShrink={"1"}
      className={classNames(styles.profileTag, {
        [styles.iconLayout]: iconLayout,
        [styles.beigeLayout]: beigeLayout,
      })}
    >
      {children}
    </Flex>
  );
});
ProfileTag.displayName = "ProfileTag";

export { YearsOfExperience, LoyalClients, SessionFormatsIcons };
